<!-- 企业名片 -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <h3 class="text-center p-50">{{hasEdit==false?'申请':'编辑'}}企业名片</h3>
                <div>
                    <el-form :model="enterApplyForm" :rules="enterApplyFormRules" ref="enterApplyForm" label-width="120px">
                        <el-form-item label="名片类型">
                            <el-select v-model="enterApplyForm.cardCategory" placeholder="请选择" style="width: 100%;">
                                <el-option v-for="item in cardCategoryList" :key="item.id" :label="item.categoryName" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="企业名称">
                            <el-autocomplete v-model="enterApplyForm.enterpriseName" style="width: 100%" disabled>
                                <template slot-scope="{ item }">
                                    <div class="name">
                                        <span class="search-addr-name">{{ item.name }}</span>
                                        <span class="search-addr-address">{{ item.legal_person_name }}</span>
                                        <span class="search-addr-tel">{{ item.reg_date }}</span>
                                    </div>
                                </template>
                                <!-- <el-button @click="enterApplyForm.name=null" v-if="!!enterApplyForm.name" type="text" style="padding-right:10px;color:#0366C3;" slot="suffix">清空</el-button> -->
                            </el-autocomplete>
                        </el-form-item>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="企业图标">
                                    <!-- <upload-image @uploadImageBack="uploadImageBack" disabled> -->
                                    <img v-if="enterApplyForm.enterpriseAvata" :src="$oucy.ossUrl+enterApplyForm.enterpriseAvata" class="avatar">
                                    <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                                    <!-- </upload-image> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="企业二维码">
                                    <upload-image @uploadImageBack="uploadImageBack">
                                        <img v-if="enterApplyForm.cardCode" :src="$oucy.ossUrl+enterApplyForm.cardCode" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </upload-image>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="企业法人">
                            <el-input v-model="enterApplyForm.enterpriseLegalPerson" disabled autocomplete="off" placeholder="填写企业法人" @input="$forceUpdate()"></el-input>
                        </el-form-item>
                        <el-form-item label="注册资金">
                            <el-input v-model="enterApplyForm.enterpriseRegCapital" disabled placeholder="填写企业店铺名称" @input="$forceUpdate()"></el-input>
                        </el-form-item>
                        <el-form-item label="成立日期">
                            <el-input v-model="enterApplyForm.enterpriseSetUpDate" disabled autocomplete="off" placeholder="填写企业签名" @input="$forceUpdate()"></el-input>
                        </el-form-item>
                        <el-form-item label="企业电话">
                            <el-input v-model="enterApplyForm.cardPhone" placeholder="填写企业电话" @input="$forceUpdate()"></el-input>
                        </el-form-item>
                        <el-form-item label="城市" v-if="isShowCityCode">
                            <el-cascader v-model="enterApplyForm.cityCode" disabled style="width: 100%" :props="cityProps" :placeholder="enterApplyForm.cityPlaceholder"></el-cascader>
                        </el-form-item>
                        <el-form-item label="营业地址">
                            <el-input v-model="enterApplyForm.enterpriseAddr" type="text" :rows="2" disabled autocomplete="off" placeholder="填写营业地址" @focus="enterApplyForm.enterpriseAddrLatitude?'':onAddrSelect(addrPoint.JYDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.JYDZ)" icon="el-icon-location" disabled type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="企业简介">
                            <div style="">
                                <el-input type="textarea" disabled :autosize="{ minRows: 6, maxRows: 10}" placeholder="请输入内容" v-model="enterApplyForm.enterpriseIntro">
                                </el-input>
                            </div>
                        </el-form-item>

                        <el-form-item>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-if="hasEdit">保存名片</el-button>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-else>提交申请</el-button>
                            <el-button @click="$oucy.back()">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
        <!-- <input style="display: none" :id="hiddenFileInputId" type="file" name="file" multiple accept="image/jpg, image/jpeg, image/png, image/gif" @change="realEditorUploadImg" /> -->
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
    </div>
</template>
<script>

import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { ltIdentity, cardCategory, cardEnterprise,enterpriseinfo } from "@/service"
export default {
    name: "EnterpriseApply1111",
    components: {
        addr,
        uploadImage
    },
    data() {
        return {
            identitys: [],
            sites: [],
            enterpriseEnterStatus: null,
            enterApplyForm: {
                identity: null,
                id: null,
                name: null,
                avata: null,
                number: null,
                sign: null,
                site: null,
                enterpriseIntro: null,
                // cityPlaceholder: '请选择',
                cityCode: null,
                enterpriseShopName: null,
                enterpriseAddr: null,
                addrGC: null,
                addrZT: null,
                agreement: null,
                cardCode: null,



                enterpriseLegalPerson: null,
                cardCategory: null,
            },
            enterApplyFormRules: {
                cardCategory: [
                    { required: true, message: '请选择名片类型', trigger: 'blur' },
                ],
                cardCode: [
                    { required: true, message: '请选择企业二维码', trigger: 'blur' },
                ],
                cardPhone: [
                    { required: true, message: '请输入企业电话', trigger: 'blur' },
                ]
            },


            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
            addrSelectVisible: false,
            addrSelectPoint: null,
            addrPoint: {
                JYDZ: 1,
                GCDZ: 2,
                ZTDZ: 3
            },
            enterprise: null,
            enterpriseId: null,
            ltIdentityList: [],
            isShowCityCode: false,
            cardEnterpriseDetail: {},
            cardCategoryList: [],
            hasEdit:null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }

        this.getCardEnterpriseDetail()
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.enterApplyForm).then(res=>{
               this[fn]() 
            },err=>{})
        },
        // 查企业名片详情
        getCardEnterpriseDetail() {
            cardEnterprise.getCardEnterpriseDetail({ id: this.enterpriseId }).then(res => {
                // 有名片数据
                if (res) {
                    this.enterApplyForm = res
                    this.enterApplyForm.cardPhone = res.enterprisePhone || res.cardPhone
                    this.enterApplyForm.cardCategory = res.cardCategoryBasic.id
                    this.hasEdit=true
                    this.enterApplyForm.cityCode=res.china && res.china.idPath
                    this.enterApplyForm.cityPlaceholder=res.china && res.china.namePath
                    this.isShowCityCode = true
                    this.getCardCategoryList()
                } else {
                    this.hasEdit=false
                    let enterprise = localGet('enterprise')
                    this.enterApplyForm = Object.assign(this.enterApplyForm, enterprise)
                    this.enterApplyForm.enterpriseId = enterprise.id;
                    this.enterApplyForm.cityPlaceholder = enterprise.china && enterprise.china.namePath;
                    this.findEnterpriseByLtUserAuthId()
                }
            })
        },
        // 发布企业名片
        createCardEnterprise() {
            cardEnterprise.createCardEnterprise(this.enterApplyForm).then(res => {
                this.$alert('发布成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 修改企业名片
        updateCardEnterprise() {
            cardEnterprise.updateCardEnterprise(this.enterApplyForm).then(res => {
                // 有名片数据
                this.$alert('修改成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 查询名片分类列表
        getCardCategoryList() {
            cardCategory.getCardCategoryList({ categoryType: 1 }).then(res => {
                this.cardCategoryList = res
                if (res && res.length) {
                    this.enterApplyForm.cardCategory = res[0].id
                }
            })
        },
        uploadImageBack(v) {
            this.enterApplyForm.cardCode = v
            console.log(v)
        },

        submit: function() {
            const me = this;
            console.log(me.enterApplyForm);
            if(!me.enterApplyForm.cardCategory){
                me.$message({
                    message: '请选择名片类型',
                    type: 'warning'
                });
                return
            }
             if(!me.enterApplyForm.cardCode){
                me.$message({
                    message: '请选择企业二维码',
                    type: 'warning'
                });
                return
            }
            if(!me.enterApplyForm.cardPhone){
                me.$message({
                    message: '请输入企业电话',
                    type: 'warning'
                });                
                return
            }
            // me.$refs["enterApplyForm"].validate((valid) => {
                // if (valid) {
            if (me.hasEdit) {
                this.updateCardEnterprise()
            } else {
                this.createCardEnterprise()
            }
                // }
            // });
        },

        onAddrSelect: function(point) {
            this.addrSelectVisible = true;
            this.addrSelectPoint = point;
        },
        addrSelectHandle: function(addr) {
            console.log('addr::', addr)
            const me = this;
            this.addrSelectVisible = false;
            let thisAddr = addr.address + '(' + addr.name + ')';
            if (!!me.addrSelectPoint) {
                if (me.addrSelectPoint === me.addrPoint.JYDZ) {
                    me.enterApplyForm.enterpriseAddrLatitude = addr.location.lat
                    me.enterApplyForm.enterpriseAddrLongtitude = addr.location.lng
                    me.enterApplyForm.enterpriseAddr = thisAddr;
                } else if (me.addrSelectPoint === me.addrPoint.GCDZ) {
                    me.enterApplyForm.addrGC = thisAddr;
                    me.enterpriseAddrGcLatitude = addr.location.lat
                    me.enterpriseAddrGcLongtitude = addr.location.lng
                } else if (me.addrSelectPoint === me.addrPoint.ZTDZ) {
                    me.enterApplyForm.addrZT = thisAddr;
                    me.enterpriseAddrZtLatitude = addr.location.lat
                    me.enterpriseAddrZtLongtitude = addr.location.lng
                }
            }
        },
        findEnterpriseByLtUserAuthId(){
            const me = this;
            oucy.postRequest('/client/enterprise/enterpriseinfo/findEnterpriseByLtUserAuthId',{
                enterpriseId:this.enterpriseId
            }).then(res=>{
                if(res && res.id){
                    this.enterApplyForm.cardPhone=res.enterprisePhone
                    this.enterApplyForm.enterpriseLegalPerson=res.enterpriseLegalPerson
                    this.enterApplyForm.enterpriseRegCapital=res.enterpriseRegCapital
                    this.enterApplyForm.enterpriseSetUpDate=res.enterpriseSetUpDate
                    this.enterApplyForm.cityCode=res.china && res.china.idPath
                    this.isShowCityCode = true
                    this.getCardCategoryList()


                }
                console.log(res.china.idPath)
                console.log(me.enterApplyForm.cityCode)
                // me.enterApplyForm.number = res.registerData.creditNo;
            });
        },


    }
}
</script>
<style scoped>
.apply-container {
    width: 500px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    line-height: normal !important;
    height: 200px;
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;
}
</style>